import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutUSRoutingModule } from './about-us-routing.module';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { WtiMaterialsModule } from 'src/app/wti-materials/wti-materials.module';
import { WtiSharesModule } from 'src/app/wti-shares/wti-shares.module';


@NgModule({
  declarations: [AboutPageComponent],
  imports: [
    CommonModule,
    WtiSharesModule,
    WtiMaterialsModule,
    AboutUSRoutingModule
  ]
})
export class AboutUSModule { }
