import { Component } from '@angular/core';
// import AOS from 'aos';

import { HeaderType } from './wti-shares/components/wti-header/wti-header.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'worldTechnologyInst';
  itsMenus: HeaderType[] = [
    {
      friendly: 'home',
      url: '/home',
    },
    {
      friendly: 'contact us',
      url: '/contact-us',
    },
    {
      friendly: 'about us',
      url: '/about-us',
    },
    {
      friendly: 'courses',
      url: '/courses',
    },
    // {
    //   friendly: 'Check In',
    //   url: '/new-letter'
    // }
  ];

  ngOnInit(): void {}
}
