import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ContactUSRoutingModule } from './contact-us-routing.module';
import { ContactPageComponent } from './components/contact-page/contact-page.component';
import { WtiMaterialsModule } from 'src/app/wti-materials/wti-materials.module';
import { WtiSharesModule } from 'src/app/wti-shares/wti-shares.module';

@NgModule({
  declarations: [ContactPageComponent],
  imports: [
    CommonModule,
    WtiSharesModule,
    WtiMaterialsModule,
    ContactUSRoutingModule,
  ],
})
export class ContactUSModule {}
