import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { ReplaySubject, Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseCard } from 'src/app/wti-shares/components/course-card/course-card.component';
import * as SquareConnect from 'square-connect';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CourseService } from '../../course.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NotifierService } from 'src/app/notifier.service';
import { TransactionSummary } from 'src/app/wti-shares/components/checkout-summary/checkout-summary.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from 'src/app/wti-shares/components/alert/alert.component';
declare var SqPaymentForm: any;

interface PurchaseDetail {
  title: string;
  details: string | string[];
}

@Component({
  selector: 'app-course-viewer',
  templateUrl: './course-viewer.component.html',
  styleUrls: ['./course-viewer.component.scss'],
})
export class CourseViewerComponent implements OnInit, OnDestroy {
  private destroyer$: ReplaySubject<boolean> = new ReplaySubject();
  @ViewChild('stepper') myStepper;
  isPaymentOpen = false;
  loading: Observable<boolean>;
  stepperLoading = false;
  error = null;
  course: CourseCard = null;
  courseDetails: PurchaseDetail[] = null;
  paymentForm: any;
  paymentConfirmation: any = null;
  enrolled = true;
  packageSelectedPrice: any = null;
  packageComparators: any = [];
  customerId: string = null;
  informationForm: FormGroup;
  packageSelectionForm: FormGroup;
  secondFormGroup: FormGroup;
  additionalPaymentMethod: FormGroup;
  isOptional = false;
  diffAmount = new FormControl('', [Validators.required]);
  packageToRegister: any = null;
  appFee = 2.99;
  processFee = 3;
  paymentInProgress: boolean = false;
  transactionSummary: TransactionSummary = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public dialog: MatDialog,
  ) {
    this.loading = this.courseService.selectedCourseLoading;
  }

  ngOnInit(): void {
    this.packageSelectionForm = this.formBuilder.group({
      done: [''],
    });
    this.informationForm = this.formBuilder.group({
      family_name: ['', [Validators.required]],
      given_name: ['', [Validators.required]],
      email_address: ['', [Validators.email, Validators.required]],
      phone_number: ['', [Validators.minLength(10), Validators.required]],
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: '',
    });
    this.additionalPaymentMethod = this.formBuilder.group({
      otherPaymentMethodPackageOpt: [
        '',
        [Validators.required, Validators.minLength(1)],
      ],
      affordableAmount: ['', [Validators.required, Validators.min(500)]],
    });

    this.activatedRoute.params.subscribe((params) => {
      // make sure that there is an ID
      if (params && params.courseId) {
        // fetch the course details
        this.courseService.getCourseDetails(params.courseId);
        this.courseService.selectedCourse
          .pipe(
            filter((c) => c !== null),
            takeUntil(this.destroyer$)
          )
          .subscribe((cDetails: any) => {
            this.course = cDetails;
            this.courseDetails = [
              {
                title: 'fall session begins:',
                details: [this.course.beginsDate],
              },
              {
                title: 'fall session ends:',
                details: [this.course.endDate],
              },
              {
                title: 'location & contact details:',
                details: _.concat(
                  this.course.location,
                  this.course.contactInfo
                ),
              },
            ];
          });
      } else {
        this.router.navigate(['/home']);
      }
    });

    this.initPayment();
  }

  setSelectedPackage(packageSelected: any, compare: any): void {
    // get the package information
    const packageDetails = _.find(packageSelected.price, price => compare.title.startsWith(price.type));
    this.isPaymentOpen = true;

    // Since there is a payment plan available that is not present in the price listing. We need to take care
    // of it here. If the packageDetails is undefined then the user definately chose payment plan and it needs to be activated.
    if (!packageDetails) {
      this.myStepper.selectedIndex = 0
      this.packageSelected({
        package: null,
        price: this.computeCoursePrice(0)
      });
    } else {
      this.packageSelected({
        package: packageDetails.type,
        price: this.computeCoursePrice(packageDetails.price)
      });
      this.myStepper.selectedIndex = 1;
      const totalDue = this.appFee + ((packageDetails.price * this.processFee) / 100) + packageDetails.price + ((packageDetails.price * 6) / 100)
      this.transactionSummary = {
        total: totalDue,
        remainingBalance: 0,
        costRecovery: this.appFee,
        amount: packageDetails.price,
        package: packageDetails.type,
        courseName: this.course.courseTitle,
        tax: (packageDetails.price * 6) / 100,
        processingFees: (packageDetails.price * this.processFee) / 100,
      }
    }
  }

  computeCoursePrice(price: number): number {
    const finalPrice = price;
    return _.toNumber(finalPrice.toFixed(2));
  }

  packageSelected(selectedPackage: any): void {
    this.additionalPaymentMethod.reset();
    this.packageSelectedPrice = selectedPackage;
    const totalDue = this.appFee + ((this.packageSelectedPrice.price * this.processFee) / 100) + this.packageSelectedPrice.price + ((this.packageSelectedPrice.price * 6) / 100)
    this.transactionSummary = {
      total: totalDue,
      remainingBalance: 0,
      costRecovery: this.appFee,
      courseName: this.course.courseTitle,
      amount: this.packageSelectedPrice.price,
      package: this.packageSelectedPrice.package,
      tax: (this.packageSelectedPrice.price * 6) / 100,
      processingFees: (this.packageSelectedPrice.price * this.processFee) / 100,
    }
  }

  fillRegistration(stepper: MatStepper): void {
    this.stepperLoading = false;
    this.packageToRegister = this.packageSelectedPrice;
    if (this.packageSelectedPrice.package === null) {
      const totalDue = this.appFee + ((this.additionalPaymentMethod.value.affordableAmount * this.processFee) / 100) + this.additionalPaymentMethod.value.affordableAmount + ((this.additionalPaymentMethod.value.affordableAmount * 6) / 100)
      this.packageToRegister.details = {
        totalDue: this.additionalPaymentMethod.value
        .otherPaymentMethodPackageOpt.price,
        advancedPayment: this.additionalPaymentMethod.value.affordableAmount,
        package: this.additionalPaymentMethod.value.otherPaymentMethodPackageOpt
        .package,
      };
      this.transactionSummary = {
        total: totalDue,
        costRecovery: this.appFee,
        courseName: this.course.courseTitle,
        package: this.packageToRegister.details.package,
        amount: this.packageToRegister.details.advancedPayment,
        tax: (this.packageToRegister.details.advancedPayment * 6) / 100,
        processingFees: (this.packageToRegister.details.advancedPayment * this.processFee) / 100,
        remainingBalance: this.packageToRegister.details.totalDue - this.packageToRegister.details.advancedPayment,
      }
    }

    stepper.next();
  }

  createCustomer(stepper: MatStepper): void {
    this.stepperLoading = true;
    this.http
      .post(
        `${environment.payment.square.protocol}://${environment.payment.square.customer.url}`,
        this.informationForm.value,
        {
          params: environment.payment.square.params,
        }
      )
      .pipe(takeUntil(this.destroyer$))
      .subscribe(
        (res: any) => {
          stepper.next();
          this.loadPaymentScript();
          this.initPayment();
          this.customerId = res.id;
          this.stepperLoading = false;
        },
        (error: any) => {
          this.error = error;
          this.stepperLoading = false;
        }
      );
  }

  private initPayment() {
    this.paymentForm = new SqPaymentForm({
      // Initialize the payment form elements
      applicationId: environment.payment.square.squareApplicationId,
      inputClass: 'sq-input',
      autoBuild: false,
      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [
        {
          fontSize: '16px',
          lineHeight: '24px',
          padding: '16px',
          placeholderColor: '#a0a0a0',
          backgroundColor: 'transparent',
        },
      ],
      // Initialize the credit card placeholders
      cardNumber: {
        elementId: 'sq-card-number',
        placeholder: 'Card Number',
      },
      cvv: {
        elementId: 'sq-cvv',
        placeholder: 'CVV',
      },
      expirationDate: {
        elementId: 'sq-expiration-date',
        placeholder: 'MM/YY',
      },
      postalCode: {
        elementId: 'sq-postal-code',
        placeholder: 'Postal',
      },
      // SqPaymentForm callback functions
      callbacks: {
        /*
         * callback function: cardNonceResponseReceived
         * Triggered when: SqPaymentForm completes a card nonce request
         */
        cardNonceResponseReceived: this.cardNonceResponseReceived.bind(this),
      },
    });
    this.paymentForm.build();
  }

  loadPaymentScript(): void {
    const node = document.createElement('script');
    node.src = environment.production
      ? 'https://js.squareup.com/v2/paymentform'
      : 'https://js.squareupsandbox.com/v2/paymentform';
    node.type = 'text/javascript';
    node.async = true;

    // append to head
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  private cardNonceResponseReceived(errors: any[], nonce: string, __: any): void {
    if (errors) {
      this.error = 'Invalid Card.'
      this.paymentInProgress = false;
      this.dialog.open(AlertComponent, {
        data: this.error
      });
    } else {
      let note = '';
      if (this.packageToRegister && this.packageToRegister.package === null) {
        note = `An advanced Payment for ${this.course.courseTitle} under ${this.transactionSummary.package} package`;
      } else {
        note = `A full payment for ${this.course.courseTitle} under ${this.transactionSummary.package} package`;
      }
      const amount = Math.floor(100 * this.transactionSummary.amount);

      this.stepperLoading = true;
      const data = {
        source_id: nonce,
        amount_money: {
          currency: 'USD',
          amount,
        },
        note: note,
        autocomplete: true,
        customer_id: this.customerId,
      };

      this.http
        .post(
          `${environment.payment.square.protocol}://${environment.payment.square.url}`,
          data,
          {
            params: environment.payment.square.params,
          }
        )
        .pipe(takeUntil(this.destroyer$))
        .subscribe(
          (res: any) => {
            this.stepperLoading = false;
            this.notifier.sendNotification('PAYMENT_CONFIRMATION', res);
            this.router.navigate([
              `/courses/${this.course.groupId}/${this.course.id}/confirmation/zotech`,
            ]);
            this.paymentInProgress = false;
          },
          (error: any) => {
            this.stepperLoading = false;
            this.paymentInProgress = false;
            this.error = error;
          }
        );
    }
  }

  onGetCardNonce(stepper: MatStepper): void {
    this.paymentInProgress = true;
    this.paymentForm.requestCardNonce();
  }

  ngOnDestroy() {
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }
}
