<section class="w-100 p-5 course-container page-container">
  <div class="w-100 course-res mx-auto" *ngIf="!(loading | async)">
    <div class="w-100 back mb-5">
      <a class="" routerLink="/courses">
        <span class="material-icons"> reply </span>
        back to course list
      </a>
    </div>
    <div class="w-100 d-flex flex-wrap justify-content-center">
      <div class="course-wrapper">
        <div
          class="course-image"
          [ngStyle]="{ 'background-image': 'url(' + course.image + ')' }"
        ></div>
        <div class="pt-5 pb-5">
          <label class="d-block font-weight-bold">{{
            course.duration | titlecase
          }}</label>
          <label class="d-block font-weight-bold">{{
            course.courseTitle.split("-")[1] | titlecase
          }}</label>
          <label class="d-block text-default">{{
            course.trainingType | titlecase
          }}</label>
          <label class="d-block">{{
            course.trainingDay + " Class from " + course.trainingTime
              | uppercase
          }}</label>
          <label class="d-block pt-4">{{ "includes: " | titlecase }}</label>
          <p class="pl-2 m-0" *ngFor="let benefit of course.trainingBenefits">
            {{ benefit | titlecase }}
          </p>
        </div>
      </div>
      <div class="pl-5 detail-wrapper">
        <label class="title font-weight-bold">{{
          course.courseTitle | uppercase
        }}</label>
        <div class="mt-3">
          <p class="package-wrapper-title">
            {{ "What is in our packages" | titlecase }}
          </p>
          <div class="d-flex black-text flex-wrap">
            <div
              *ngFor="let compare of course.packageComparators"
              style="padding: 10px;"
            >
              <label class="package-label" (click)='setSelectedPackage(course, compare)'>
                <mdb-badge danger="true" pill="true" class="p-3" style="font-size: larger;">{{
                  compare.title | titlecase
                }}</mdb-badge>
              </label>
              <li *ngFor="let pkgDetail of compare.details">{{ pkgDetail | titlecase }}</li>
            </div>
          </div>
        </div>
        <button
          mdbBtn
          type="button"
          color="danger"
          mdbWavesEffect
          class="w-100 ml-0 mt-4 mb-4"
          (click)='isPaymentOpen = true'
        >
          {{ "Pay now" | titlecase }}
        </button>
        <mat-accordion>
          <mat-expansion-panel hideToggle [expanded]="isPaymentOpen" class="no-padding">
            <mat-vertical-stepper #stepper class="stepper-padding">
              <mat-step [stepControl]="packageSelectionForm">
                <mat-spinner *ngIf="stepperLoading"></mat-spinner>
                <ng-template matStepLabel>Select Your Packages</ng-template>
                <mat-radio-group
                  aria-label="Select an option"
                  class="select-wrap"
                  [selected]='packageSelectedPrice'
                  [value]='packageSelectedPrice'
                >
                  <div *ngFor="let pkg of course.price">
                    <label class="main-color price font-weight-bold">{{
                      pkg.type + " package" | uppercase
                    }}</label>
                    <mat-radio-button
                    [checked]='packageSelectedPrice !== null && packageSelectedPrice.package === pkg.type'
                      class="w-100"
                      [value]="{package: pkg.type, price: computeCoursePrice(pkg.price)}"
                      (change)="
                        packageSelected({
                          package: pkg.type,
                          price: computeCoursePrice(pkg.price)
                        })
                      "
                      >{{
                        computeCoursePrice(pkg.price) | currency
                      }}</mat-radio-button
                    >
                  </div>
                  <div>
                    <label class="main-color price font-weight-bold">{{
                      "payment plan" + " package" | uppercase
                    }}</label>
                    <mat-radio-button
                      class="w-100"
                      [checked]='packageSelectedPrice !== null && packageSelectedPrice.package === null'
                      [value]="{package:null, price: 0}"
                      (change)="
                        packageSelected({
                          package: null,
                          price: computeCoursePrice(0)
                        })
                      "
                      >{{
                        "pay payment arrangement" | titlecase
                      }}</mat-radio-button
                    >
                  </div>
                </mat-radio-group>
                <div
                  class="additional"
                  [ngClass]="{
                    'arrPayment animated fadeInDown':
                      packageSelectedPrice && packageSelectedPrice.price === 0
                  }"
                >
                  <form
                    [formGroup]="additionalPaymentMethod"
                    *ngIf="!stepperLoading && packageSelectedPrice?.package === null"
                  >
                    <mat-form-field appearance="fill" class="full">
                      <mat-label>{{ "select a package" | titlecase }}</mat-label>
                      <mat-select formControlName="otherPaymentMethodPackageOpt">
                        <mat-option
                          *ngFor="let coursePackage of course.price"
                          [value]="{
                            package: coursePackage.type,
                            price: computeCoursePrice(coursePackage.price)
                          }"
                        >
                          {{ coursePackage.type + " " + "package" | titlecase }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          additionalPaymentMethod
                            .get('otherPaymentMethodPackageOpt')
                            .hasError('required')
                        "
                        >{{ "you must select a package" | titlecase }}</mat-error
                      >
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="full">
                      <mat-label>Affordable Amount</mat-label>
                      <input
                        matInput
                        type="number"
                        placeholder="Enter your amount"
                        formControlName="affordableAmount"
                      />
                      <span matPrefix>$&nbsp;</span>
                      <mat-error
                        *ngIf="
                          additionalPaymentMethod
                            .get('affordableAmount')
                            .hasError('required')
                        "
                        >{{ "you must enter an amount" | titlecase }}</mat-error
                      >
                      <mat-error
                        *ngIf="
                          additionalPaymentMethod
                            .get('affordableAmount')
                            .hasError('min')
                        "
                        >{{ "minimum payment is $500" | titlecase }}</mat-error
                      >
                    </mat-form-field>
                  </form>
                </div>
                <div class="d-block">
                  <button
                    mat-raised-button
                    color="primary"
                    [disabled]="
                      !packageSelectedPrice ||
                      (!packageSelectedPrice.package &&
                        additionalPaymentMethod.invalid)
                    "
                    (click)="fillRegistration(stepper)"
                  >
                    Continue Registration
                  </button>
                </div>
              </mat-step>
              <mat-step [stepControl]="informationForm" #userInfo>
                <mat-spinner *ngIf="stepperLoading"></mat-spinner>
                <div class="w-100" *ngIf="transactionSummary !== null">
                  <ng-template matStepLabel>Transaction Summary</ng-template>
                  <app-checkout-summary
                    [summary]="this.transactionSummary"
                  ></app-checkout-summary>
                  <div class="d-flex justify-content-between flex-wrap">
                    <button mat-raised-button matStepperPrevious class="mb-2">return to packages</button>
                    <button mat-raised-button color="primary" matStepperNext class="mt-2">
                      continue to payment
                    </button>
                  </div>
                </div>
              </mat-step>
              <mat-step [stepControl]="informationForm" #userInfo>
                <mat-spinner *ngIf="stepperLoading"></mat-spinner>
                <form [formGroup]="informationForm" *ngIf="!stepperLoading">
                  <ng-template matStepLabel>Student Information</ng-template>
                  <mat-form-field>
                    <mat-label>{{ "last name" | titlecase }}</mat-label>
                    <input
                      matInput
                      placeholder="Enter Your Last Name"
                      formControlName="family_name"
                      required
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{ "first name" | titlecase }}</mat-label>
                    <input
                      matInput
                      placeholder="Enter Your first Name"
                      formControlName="given_name"
                      required
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{ "email address" | titlecase }}</mat-label>
                    <input
                      matInput
                      placeholder="Enter Your Email Address"
                      formControlName="email_address"
                      required
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{ "phone number" | titlecase }}</mat-label>
                    <input
                      matInput
                      placeholder="Enter Your Phone Number"
                      formControlName="phone_number"
                      required
                    />
                  </mat-form-field>
                  <div class="d-block">
                    <button
                      mat-raised-button
                      color="primary"
                      [disabled]="informationForm.invalid"
                      (click)="createCustomer(stepper)"
                    >
                      continue to payment
                    </button>
                  </div>
                </form>
              </mat-step>
              <mat-step [stepControl]="secondFormGroup" [optional]="isOptional">
                <form [formGroup]="secondFormGroup">
                  <ng-template matStepLabel>{{'make payment' | titlecase}}</ng-template>
                  <div id="form-container">
                    <div id="sq-card-number"></div>
                    <div class="third" id="sq-expiration-date"></div>
                    <div class="third" id="sq-cvv"></div>
                    <div class="third" id="sq-postal-code"></div>
                    <button
                      id="sq-creditcard"
                      class="button-credit-card"
                      (click)="onGetCardNonce(stepper)"
                    >
                      Pay Now
                    </button>
                  </div>
                </form>
              </mat-step>
            </mat-vertical-stepper>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel hideToggle *ngFor="let detail of courseDetails">
            <mat-expansion-panel-header>
              <mat-panel-title class="d-flex justify-content-between">
                {{ detail.title | uppercase }}
                <span class="material-icons"> add </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p
              class="m-0"
              *ngFor="let content of detail.details; let i = index"
              [ngClass]="{ 'font-weight-bold': i === 0, 'pt-4': i === 2 }"
            >
              {{ content | titlecase }}
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="w-100 overview">
      <label class="w-100 x-title font-weight-bold">Course overview</label>
      <p class="w-100 pl-3 over-text">{{ course.courseDescription }}</p>
      <br />
      <label class="w-100 x-title font-weight-bold"
        >End of course Outcome
      </label>
      <div class="w-100 d-flex flex-wrap">
        <p
          class="d-flex pl-3 over-text full"
          *ngFor="let outcome of course.courseOutcomes"
        >
          <mdb-badge pill="true" danger="true" class="mobile">{{ outcome }}</mdb-badge>
        </p>
      </div>
    </div>
    <div class="w-100">
      <a class="" routerLink="/courses">
        <span class="material-icons"> reply </span>
        back to course list
      </a>
    </div>
  </div>
  <p class="error" *ngIf="error">
    {{ error }} <a routerLink="/home">Go Home</a>
  </p>
  <div class="payment-hold position-fixed" *ngIf="paymentInProgress">
    <mat-spinner [color]="'accent'"></mat-spinner>
  </div>
</section>
