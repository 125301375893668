import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CourseViewerComponent } from './components/course-viewer/course-viewer.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { CourseListComponent } from './components/course-list/course-list.component';

const routes: Routes = [
  {
    path: 'courses',
    children: [
      {
        path: '',
        component: CourseListComponent
      },
      {
        path: ':groupId/:courseId',
        component: CourseViewerComponent,
      },
      {
        path: ':groupId/:courseId/confirmation/:purchaseId',
        component: ConfirmationComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoursesRoutingModule {}
