import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})
export class ContactPageComponent implements OnInit {
  buttonTxt = 'send';
  acknowledgment = 'sending...';
  acknowledgmentTtile = 'Email Confirmation';
  message =
    'Your message / email is being send to I.T Training School Admission Department';

  contactForm: FormGroup;

  constructor(private _fb: FormBuilder, private _http: HttpClient) {}

  ngOnInit(): void {
    this.contactForm = this._fb.group({
      visitorName: ['', [Validators.minLength(1), Validators.required]],
      visitorPhoneNumber: ['', [Validators.minLength(10), Validators.required]],
      visitorEmail: ['', [Validators.email, Validators.required]],
      visitorMessageSubject: ['', [Validators.required]],
      visitorEmailSource: ['Our Website', [Validators.required]],
      visitorMessage: ['', [Validators.minLength(1), Validators.required]],
    });
  }

  submit() {
    if (this.contactForm.valid) {
      this.buttonTxt = 'sending ...';
      this.acknowledgmentTtile = 'Email Confirmation Pending ...';
      const user = {
        name: this.contactForm.value.visitorName,
        phone_number: this.contactForm.value.visitorPhoneNumber,
        sender_email: this.contactForm.value.visitorEmail,
        subject: this.contactForm.value.visitorMessageSubject,
        source: this.contactForm.value.visitorEmailSource,
        content: this.contactForm.value.visitorMessage,
      };
      this._http
        .post(
          `${environment.emailService.protocol}://${environment.emailService.url}?token=${environment.emailService.token}`,
          user
        )
        .subscribe(
          (data) => {
            const res: any = data;
          },
          (err) => {
            this.buttonTxt = 'send';
          },
          () => {
            this.buttonTxt = 'send';
            this.acknowledgmentTtile = 'Email send';
            this.message = 'Your email has been send...';
            this.acknowledgment = 'thank you';
            this.contactForm.reset();
          }
        );
    }
  }
}
