import { NewLetterModule } from './pages/new-letter/new-letter.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WtiMaterialsModule } from './wti-materials/wti-materials.module';
import { WtiSharesModule } from './wti-shares/wti-shares.module';
import { HomeModule } from './pages/home/home.module';
import { CoursesModule } from './pages/courses/courses.module';
import { NgxPayPalModule } from 'ngx-paypal';
import { HttpClientModule } from '@angular/common/http';
import { ContactUSModule } from './pages/contact-us/contact-us.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { AboutUSModule } from './pages/about-us/about-us.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    WtiSharesModule,
    WtiMaterialsModule,
    HomeModule,
    AboutUSModule,
    CoursesModule,
    AboutUSModule,
    ContactUSModule,
    NewLetterModule,
    NgxPayPalModule,
    HttpClientModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: environment.amgApiKey,
    }),
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    FormlyMaterialModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
