<div class="contact-wrap text-white page-container">
  <div class="contact-header">
    <label class="animated slideInLeft p-5">Contact Us</label><br />
    <label class="p-5 animated slideInRight">
      {{ "get in touch with us" | titlecase }}
    </label>
  </div>
  <div class="w-100 d-flex align-items-center flex-row c-content-wrap">
    <div class="text-block p-3">
      <div>
        <img src="../../../../../assets/logo/zologo.jpeg" />
      </div>
      <h3 class="black-text pl-2 ml-2">
        To make requests for further information,
        <mark>Contact Us</mark>
        via telephone, email, or our social channels.
      </h3>
      <div>
        <div class="black-text d-flex flex-wrap contact-list">
          <p class="black-text mr-3">
            <button mat-mini-fab color="warn">
              <mat-icon>perm_phone_msg</mat-icon>
            </button>
            (240) 770 - 6366
          </p>
          <p class="black-text">
            <button mat-mini-fab color="warn">
              <mat-icon>perm_phone_msg</mat-icon>
            </button>
            (240) 770 - 8960
          </p>
          <p class="pl-3">
            <button mat-mini-fab color="warn">
              <mat-icon>mark_email_unread</mat-icon>
            </button>
            info@ittrainingschool.info
          </p>
        </div>
      </div>
    </div>
    <div class="horizontal"></div>
    <!-- <form [formGroup]="contactForm" (ngSubmit)="submit()">
      <div class="w-100 p-5 mat-elevation-z10">
        <div class="user-info">
          <mat-form-field appearance="outline" class="input-field-zo">
            <mat-label>Full Name</mat-label>
            <input
              matInput
              placeholder="Name*"
              type="text"
              id="userName"
              formControlName="visitorName"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="input-field-zo">
            <mat-label>Phone Number</mat-label>
            <input
              matInput
              placeholder="phone number*"
              type="text"
              id="phoneNumber"
              formControlName="visitorPhoneNumber"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="input-field-zo">
            <mat-label>Email Address</mat-label>
            <input
              matInput
              placeholder="Email address"
              type="text"
              id="userEmail"
              formControlName="visitorEmail"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Your Subject</mat-label>
            <input
              matInput
              placeholder="Your subject"
              type="text"
              id="subject"
              formControlName="visitorMessageSubject"
            />
          </mat-form-field>
        </div>
        <div class="user-detail">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Your messages</mat-label>
            <textarea
              matInput
              id="userMessage"
              class=""
              formControlName="visitorMessage"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="submission">
          <button
            type="submit"
            mdbBtn
            class="bg-color w-50 send-btn"
            mdbWavesEffect
            [disabled]="contactForm.invalid"
            (click)="basicModal.show()"
          >
            {{ buttonTxt }}
          </button>
        </div>
      </div>
    </form> -->
  </div>
  <div class="confirmation">
    <div
      mdbModal
      #basicModal="mdbModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myBasicModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content bg-color">
          <div class="modal-header">
            <button
              type="button"
              class="close pull-right"
              aria-label="Close"
              (click)="basicModal.hide()"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">
              {{ acknowledgmentTtile }}
            </h4>
          </div>
          <div class="modal-body">
            {{ message }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              mdbBtn
              color="light"
              class="relative waves-light"
              (click)="basicModal.hide()"
              mdbWavesEffect
            >
              {{ acknowledgment }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
