<section class="w-100 page-container">
    <section class="w-100 text-white">
        <div class="course-list-top position-relative">
            <div class="position-absolute course-list-title">
            <h1 class="w-100 pb-4 animated slideInLeft main-color">
                <mark class="p-3">{{ "list of available courses" | titlecase }}</mark>
            </h1>
            </div>
        </div>
        </section>
        <section class="w-100" *ngIf='!(courseLoading | async)'>
            <div class="w-75 mx-auto m-4" *ngFor="let course of (coursesList | async)">
                <app-course-card [isItAllList]="true" [course]="course"></app-course-card>
            </div>
        </section>
        <section class="w-100 text-center" *ngIf='courseLoading | async'>
            <div class="spinner-border text-danger" role="status">
                <span class="sr-only">Loading...</span>
              </div>
      </section>

      <section class="w-100" *ngIf='courseError | async'>
        <h1>Put the error here {{courseError | async}}</h1>
    </section>
</section>
