<section class="w-100 page-container">
  <section class="w-100 text-white about-container">
    <div class="about-top position-relative">
      <div class="position-absolute about-title">
        <h1 class="w-100 pb-4 animated slideInLeft">
          {{ "about" | uppercase }}
        </h1>
        <label class="m-0 animated bounceInDown">I.T Training School</label>
        <p class="animated slideInRight text-right">The Power of knowledge</p>
      </div>
    </div>
  </section>
  <section class="w-100 p-5 about-wrap">
    <div class="w-100">
        <h1 class="text-center default-signature about-name pt-5">{{'about us in bullet' | titlecase}}</h1>
        <div class="w-100 p-5 about-res">
            <div class="w-100 about-item d-flex justify-content-center pb-4" *ngFor="let about of aboutUsList">
                <div class="about-img">
                    <img [src]="about.logo" />
                </div>
                <div class="pl-2 w-50 item-detail">
                    <label class="about-item-title">{{about.title | titlecase}}</label>
                    <p class="">{{about.details}}</p>
                </div>
            </div>
        </div>
    </div>
  </section>
  <section class="w-100 about-banner bg-color text-center p-5 text-white">
    <label class="w-50 banner-title animated slideInLeft">{{'your professional success' | uppercase}} <br> {{'is a community effort' | uppercase}}</label>
    <div class="animated slideInRight">You can always lean on our in-house team of experts to provide guidance and support whenever you need it.</div>
    <div>All of our graduates receive career coaching, interview-readiness training, and direct access to recruiters and potential employers.</div>
    <div class="w-100 text-center pt-5">
        <div class="zoLogoItem mx-auto">
            <img [src]="'../../../../../assets/logo/logo_1.png'" />
        </div>
    </div>
  </section>
  <section class="w-100 text-center">
    <div class="about-footer">
        <img [src]="'../../../../assets/logo/zologo.jpeg'" />
    </div>
  </section>
</section>
